import React from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from '@wix/tpa-router/react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { selectDialog, selectGroupRequirements } from 'store/selectors';

import { useController } from 'common/context/controller';

import { Button } from 'wui/Button';
import { AlertDialog } from 'wui/AlertDialog';
import { DialogTitle } from 'wui/DialogTitle';
import { DialogContent } from 'wui/DialogContent';
import { DialogContentText } from 'wui/DialogContentText';
import { DialogActions } from 'wui/DialogActions';

import { usePricingPlansQueryParams } from './usePricingPlansQueryParams';
import { usePaidPlansBi } from './bi';

export const PricingPlansRestrictionDialog: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const router = useRouter();

  const { application$ } = useController();

  const bi = usePaidPlansBi();

  const dialog = useSelector(selectDialog('paidPlansRestriction'));
  const groupId = dialog.params?.groupId as string;

  const requirements = useSelector(selectGroupRequirements(groupId));
  const queryParams = usePricingPlansQueryParams(groupId);

  return (
    <AlertDialog
      isOpen={dialog.isOpen}
      onClose={handleClose}
      aria-labelledby="ppr-dialog-title"
      aria-describedby="ppr-dialog-content"
    >
      <DialogTitle
        alert
        id="ppr-dialog-title"
        title={t('groups-web.restriction.plans.dialog.title')}
      />
      <DialogContent alert>
        <DialogContentText alert id="ppr-dialog-content">
          {t('groups-web.restriction.plans.dialog.subtitle')}
        </DialogContentText>
      </DialogContent>
      <DialogActions alert>
        <Button
          outlined
          variant="basic"
          onClick={handleClose}
          fullWidth={isMobile}
        >
          {t('groups-web.cancel')}
        </Button>
        <Button variant="basic" onClick={handleSubmit} fullWidth={isMobile}>
          {t('groups-web.restriction.plans.dialog.cta')}
        </Button>
      </DialogActions>
    </AlertDialog>
  );

  function handleSubmit() {
    if (
      requirements?.violation?.pricingPlansOptions &&
      !requirements.violation.pricingPlansOptions.installed
    ) {
      return;
    }

    bi.getPlanClick();
    router.go('pricing-plans', {
      appSectionParams: queryParams,
    });
  }

  function handleClose() {
    application$.closeDialog('paidPlansRestriction');
  }
};

PricingPlansRestrictionDialog.displayName = 'PricingPlansRestrictionDialog';
