import React from 'react';
import cls from 'classnames';
import {
  Tooltip as TooltipTPA,
  TooltipProps,
  TooltipSkin,
} from 'wix-ui-tpa/cssVars';

import { Typography } from '../Typography';
import { usePortalContainer } from '../Portal';

import classes from './Tooltip.scss';

export interface ITooltipProps extends TooltipProps {}

export function Tooltip(props: ITooltipProps) {
  const { className, contentClassName, content, ...rest } = props;

  const container = usePortalContainer();

  if (!content) {
    return <>{props.children ?? null}</>;
  }

  return (
    <TooltipTPA
      fixed
      showArrow
      appendTo={container}
      skin={TooltipSkin.Wired}
      disableClickOutsideWhenClosed
      className={cls(classes.root, className)}
      zIndex={classes.zIndex as unknown as number}
      contentClassName={cls(classes.content, contentClassName)}
      content={
        <Typography variant="p2-12" as="div">
          {content}
        </Typography>
      }
      {...rest}
    />
  );
}

Tooltip.displayName = 'wui/Tooltip';
Tooltip.defaultProps = {};
